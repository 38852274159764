/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, createContext } from 'react';
import { Auth, getAuth, User } from 'firebase/auth';
import { FirebaseApp } from 'firebase/app';
import { ref, getDatabase, onValue } from 'firebase/database';
import { userSleeve } from '../react-app-env';

type UserSleeve = typeof userSleeve;

type FBAuthContextType = {
    fbAuth: Auth | null;
    fbUser: User | null;
    userSleeve: UserSleeve | null;
};

type FBAuthProviderProps = React.PropsWithChildren<{
    fbApp: FirebaseApp;
}>;

const FBAuthContext = createContext<FBAuthContextType>({
    fbAuth: null,
    fbUser: null,
    userSleeve: null,
});

const FBAuthProvider: React.FC<FBAuthProviderProps> = (props: FBAuthProviderProps) => {
    const [fbAuth] = useState(getAuth(props.fbApp));
    const [fbUser, setFbUser] = useState<User | null>(null);
    const [userSleeve, setUserSleeve] = useState<UserSleeve | null>(null);

    useEffect(() => {
        let userRef: any;

        const userRefCallback = (snapshot: any) => {
            const data = snapshot.val();
            setUserSleeve(data);
        };

        if (fbUser) {
            const uid = fbUser.uid;
            const db = getDatabase();
            userRef = ref(db, `users/${uid}`);
            onValue(userRef, userRefCallback);
        }

        return () => {
            if (userRef) {
                onValue(userRef, userRefCallback);
            }
        };
    }, [fbUser]);

    useEffect(() => {
        const unsubscribe = fbAuth.onAuthStateChanged((user) => {
            setFbUser(user);
            return false;
        });

        return () => unsubscribe();
    }, [fbAuth]);

    return (
        <FBAuthContext.Provider value={{ fbAuth, fbUser, userSleeve }}>
            {props.children}
        </FBAuthContext.Provider>
    );
};

export default FBAuthContext;
export { FBAuthProvider };
