/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

interface Chart {
  toBase64Image: () => string;
  fileName: string;
}

interface AdditionalChart {
  chart: Chart;
  fileName: string;
}

interface CardExportButtonProps {
  chartRef?: React.RefObject<any>;
  supportedFileTypes: string[];
  fileName: string;
  additionalCharts?: AdditionalChart[];
  exportOptions: string[];
  tableData?: Array<Array<any>>;
  headers?: Array<string>;
  disabled?: boolean
}

const CardExportDropdown: React.FunctionComponent<CardExportButtonProps> = (props) => {
  const handleExportButtonClick = (fileType: string, fileName: string) => {
    switch (fileType) {
      case 'pdf':
        if (props.chartRef && props.chartRef.current) {
          const chart = props.chartRef.current;
          const aspectRatio = chart.width / chart.height;
          const pdfWidth = 297 / 2; // A4 page width in mm, 50% size
          const pdfHeight = pdfWidth / aspectRatio;
          const pdf = new jsPDF('landscape', 'mm', 'a4');
          const x = (pdf.internal.pageSize.width - pdfWidth) / 2;
          const y = (pdf.internal.pageSize.height - pdfHeight) / 2;
          pdf.addImage(chart.toBase64Image(), 'PNG', x, y, pdfWidth, pdfHeight);
          pdf.save(`${fileName}.pdf`);
        } else {
          console.error(`No chart data to export as PDF`);
        }
        break;

      case 'csv':
        if (props.tableData) {
          let csvData = props.tableData.map((item) => {
            return item.join(',');
          });
          if (props.headers) {
            csvData = [props.headers.join(',')].concat(csvData);
          }
          const csvContent = `data:text/csv;charset=utf-8,${csvData.join('\n')}`;
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `${fileName}.csv`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error(`No data to export as CSV`);
        }
        break;
        case 'xlsx':
            if (props.tableData) {
              const columnOrder = props.headers;
              const sheetData = props.tableData.map((item) => {
                const rowData: any = {};
                for (let j = 0; columnOrder && j < columnOrder.length; j++) {
                  rowData[columnOrder[j]] = item[j];
                }
                return rowData;
              });
              const ws = XLSX.utils.json_to_sheet(sheetData, { header: columnOrder });
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, fileName);
              const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
              const blob = new Blob([xlsxData], { type: 'application/octet-stream' });
              saveAs(blob, `${fileName}.xlsx`);
            } else {
              console.error(`No data to export as XLSX`);
            }
            break;
          
        case 'png':
          if (props.chartRef) {
            const pngData = props.chartRef.current.toBase64Image();
            const fileLink = document.createElement('a');
            fileLink.download = `${fileName}.png`;
            fileLink.href = pngData;
            fileLink.click();
          } else {
            console.error(`No chart data to export as PNG`);
          }
          break;
  
        case 'zip':{
          const zip = new JSZip();
          if (props.chartRef) {
            zip.file(`${fileName}.pdf`, props.chartRef.current.toBase64Image(), { base64: true });
            zip.file(`${fileName}.png`, props.chartRef.current.toBase64Image(), { base64: true });
          } else {
            console.error(`No chart data to export to ZIP file`);
          }
          if (props.tableData) {
            const columnOrder = props.headers;
            const sheetData = props.tableData.map((item) => {
              const rowData: any = {};
              for (let j = 0; columnOrder && j < columnOrder.length; j++) {
                rowData[columnOrder[j]] = item[j];
              }
              return rowData;
            });
            const ws = XLSX.utils.json_to_sheet(sheetData, { header: columnOrder });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, props.fileName);
            const xlsxFilePromise = new Promise((resolve, reject) => {
              try {
                const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                resolve(xlsxData);
              } catch (error) {
                reject(error);
              }
            });
            xlsxFilePromise.then((xlsxData: any) => {
              zip.file(`${fileName}.xlsx`, xlsxData, { binary: true });
              zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, `${fileName}.zip`);
              });
            }).catch((error: any) => {
              console.error(`Error creating XLSX for ZIP file: ${error}`);
            });
          } else {
            console.error(`No data to export to ZIP file`);
          }
          break;
        }
  
        default:
          console.error(`Unsupported file type: ${fileType}`);
          break;
      }
    };
    return (
        <DropdownButton variant="outline-primary" size="sm" id="dropdown-basic-button" disabled={props.disabled ? props.disabled : false} title={<FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>}>
          {props.supportedFileTypes.includes('PNG') && <Dropdown.Item onClick={() => handleExportButtonClick('png', props.fileName)}>PNG</Dropdown.Item>}
          {props.supportedFileTypes.includes('PDF') && <Dropdown.Item onClick={() => handleExportButtonClick('pdf', props.fileName)}>PDF</Dropdown.Item>}
          {props.supportedFileTypes.includes('CSV') && <Dropdown.Item onClick={() => handleExportButtonClick('csv', props.fileName)}>CSV</Dropdown.Item>}
          {props.supportedFileTypes.includes('XLSX') && <Dropdown.Item onClick={() => handleExportButtonClick('xlsx', props.fileName)}>XLSX</Dropdown.Item>}
        </DropdownButton>
      );
    };
  
    export default CardExportDropdown;

  














// // React imports
// import React, { RefObject } from 'react';

// // React Bootstrap imports
// import { Dropdown, DropdownButton } from 'react-bootstrap';

// // Font awesome imports
// import { faFileExport } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// // Various File Creation Imports
// import jsPDF from 'jspdf';
// import JSZip from 'jszip';
// import { saveAs } from 'file-saver';
// import * as XLSX from 'xlsx';

// import { TypedChartComponent } from 'react-chartjs-2/dist/types';

// interface Chart {
//     toBase64Image: () => string;
//     fileName: string;
// }

// interface AdditionalChart {
//     chart: Chart;
//     fileName: string;
// }

// // interface ExportDataType {
// //     type: string;
// //     data: string;
// //     fileName: string; // new property
// // }

// interface CardExportButtonProps {
//     chartRef?: RefObject<TypedChartComponent<'bar'> | TypedChartComponent<'pie'> | any>;
//     supportedFileTypes: string[];
//     fileName: string;
//     additionalCharts?: AdditionalChart[];
//     exportOptions: string[];
//     tableData?: Array<Array<any>>;
//     headers?: Array<string>;
// }

// const CardExportDropdown: React.FunctionComponent<CardExportButtonProps> = (props: CardExportButtonProps) => {

//     const handleExportButtonClick = (fileType: string, fileName: string) => {
//         switch (fileType) {
//             case 'pdf':
//                 if (props.chartRef && props.chartRef.current) {
//                   const chart = props.chartRef.current;
//                   const aspectRatio = chart.width / chart.height;
//                   const pdfWidth = 297 / 2; // A4 page width in mm, 50% size
//                   const pdfHeight = pdfWidth / aspectRatio;
//                   const pdf = new jsPDF('landscape', 'mm', 'a4');
//                   const x = (pdf.internal.pageSize.width - pdfWidth) / 2;
//                   const y = (pdf.internal.pageSize.height - pdfHeight) / 2;
//                   pdf.addImage(chart.toBase64Image(), 'PNG', x, y, pdfWidth, pdfHeight);
//                   pdf.save(`${fileName}.pdf`);
//                 } else {
//                   console.error(`No chart data to export as PDF`);
//                 }
//                 break;
              
//             case 'csv':
//                 if (props.tableData) {
//                     let csvData = props.tableData.map((item) => {
//                         return item.join(',');
//                     });
//                     if (props.headers) {
//                         csvData = [props.headers.join(',')].concat(csvData);
//                     }
//                     const csvContent = `data:text/csv;charset=utf-8,${csvData.join('\n')}`;
//                     const encodedUri = encodeURI(csvContent);
//                     const link = document.createElement('a');
//                     link.setAttribute('href', encodedUri);
//                     link.setAttribute('download', `${fileName}.csv`);
//                     document.body.appendChild(link);
//                     link.click();
//                 } else {
//                     console.error(`No data to export as CSV`);
//                 }
//                 break;
//             case 'xlsx':
//                 if (props.tableData) {
//                     const wb = XLSX.utils.book_new();
//                     const columnOrder = props.headers;
//                     const sheetData = props.tableData.map((item) => {
//                         const rowData: any = {};
//                         for (let j = 0; columnOrder && j < columnOrder.length; j++) {
//                             rowData[columnOrder[j]] = item[j];
//                         }
//                         return rowData;
//                     });
//                     const ws = XLSX.utils.json_to_sheet(sheetData, { header: columnOrder });

//                     if (columnOrder) {
//                         // auto size columns based on content
//                         const colWidths = columnOrder.map((columnName, colIndex) => {
//                             const colData = sheetData.map((row) => row[columnName] ?? '');
//                             const colLen = Math.max(...colData.map((value: string) => value.toString().length));
//                             return { width: colLen + 1 };
//                         });
//                         ws['!cols'] = colWidths;
//                     }

//                     XLSX.utils.book_append_sheet(wb, ws, fileName);
//                     XLSX.writeFile(wb, `${fileName}.xlsx`);
//                 } else {
//                     console.error(`No data to export as XLSX`);
//                 }
//                 break;
//             case 'png':
//                 if (props.chartRef) {
//                     const pngData = props.chartRef.current.toBase64Image();
//                     const fileLink = document.createElement('a');
//                     fileLink.download = `${fileName}.png`;
//                     fileLink.href = pngData;
//                     fileLink.click();
//                 } else {
//                     console.error(`No chart data to export as PNG`);
//                 }
//                 break;
//             case 'zip':
//                 const zip = new JSZip();
//                 if (props.chartRef) {
//                     zip.file(`${fileName}.pdf`, props.chartRef.current.toBase64Image(), { base64: true });
//                     zip.file(`${fileName}.png`, props.chartRef.current.toBase64Image(), { base64: true });
//                 } else {
//                     console.error(`No chart data to export to ZIP file`);
//                 }
//                 if (props.tableData) {
//                     const columnOrder = props.headers;
//                     const sheetData = props.tableData.map((item) => {
//                         const rowData: any = {};
//                         for (let j = 0; columnOrder && j < columnOrder.length; j++) {
//                             rowData[columnOrder[j]] = item[j];
//                         }
//                         return rowData;
//                     });
//                     const ws = XLSX.utils.json_to_sheet(sheetData, { header: columnOrder });
//                     const wb = XLSX.utils.book_new();
//                     XLSX.utils.book_append_sheet(wb, ws, props.fileName);
//                     const xlsxFilePromise = new Promise((resolve, reject) => {
//                         try {
//                             const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//                             resolve(xlsxData);
//                         } catch (error) {
//                             reject(error);
//                         }
//                     });
//                     xlsxFilePromise.then((xlsxData: any) => {
//                         zip.file(`${fileName}.xlsx`, xlsxData, { binary: true });
//                         zip.generateAsync({ type: 'blob' }).then((content) => {
//                             saveAs(content, `${fileName}.zip`);
//                         });
//                     }).catch((error: any) => {
//                         console.error(`Error creating XLSX for ZIP file: ${error}`);
//                     });
//                 } else {
//                     console.error(`No data to export to ZIP file`);
//                 }
//                 break;

//             default:
//                 console.error(`Unsupported file type: ${fileType}`);
//                 break;
//         }
//     };

//     return (
//         <DropdownButton variant="outline-primary" size="sm" id="dropdown-basic-button" title={<FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>}>
//             {/* {props.supportedFileTypes.includes('ALL') && <Dropdown.Item onClick={handleExportAllClick}>All</Dropdown.Item>} */}
//             {props.supportedFileTypes.includes('PNG') && <Dropdown.Item onClick={() => handleExportButtonClick('png', props.fileName)}>PNG</Dropdown.Item>}
//             {props.supportedFileTypes.includes('PDF') && <Dropdown.Item onClick={() => handleExportButtonClick('pdf', props.fileName)}>PDF</Dropdown.Item>}
//             {props.supportedFileTypes.includes('CSV') && <Dropdown.Item onClick={() => handleExportButtonClick('csv', props.fileName)}>CSV</Dropdown.Item>}
//             {props.supportedFileTypes.includes('XLSX') && <Dropdown.Item onClick={() => handleExportButtonClick('xlsx', props.fileName)}>XLSX</Dropdown.Item>}
//         </DropdownButton>
//     );
// };

// export default CardExportDropdown;