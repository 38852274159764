/**
 * Formats a provided Date object to the specified formatType.
 *
 * @param date - The Date object to format.
 * @param formatType - Optional. The format string, which can be any format supported by the Date() object.
 * 
 * @returns The formatted date string.
 *
 * ----------------------------------
 * 
 * Usage example:
 * 
 * const d = new Date();
 * 
 * const formattedDate = formatDate(d, 'MM/DD/YYYY hh:mm:ss A');
 * 
 * console.log(formattedDate); // Output: '02/19/2023 10:15:30 PM'
 *
 * ----------------------------------
 * 
 * Example formatType parameters:
 * 
 * YYYY - 4-digit year
 * 
 * MM - 2-digit month
 * 
 * DD - 2-digit day
 * 
 * hh - 2-digit hour (24-hour format)
 * 
 * mm - 2-digit minute
 * 
 * ss - 2-digit second
 * 
 * A - AM/PM marker
 */
export function formatDate(date: Date | string, formatType: string = 'YYYY-MM-DD'): string {
    const d = typeof date === 'string' ? new Date(date) : date;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const formats: Record<string, Function> = {
        YYYY: () => d.getFullYear(),
        MM: () => (d.getMonth() + 1).toString().padStart(2, '0'),
        DD: () => d.getDate().toString().padStart(2, '0'),
        hh: () => (d.getHours() % 12 || 12).toString().padStart(2, '0'),
        mm: () => d.getMinutes().toString().padStart(2, '0'),
        ss: () => d.getSeconds().toString().padStart(2, '0'),
        A: () => (d.getHours() >= 12 ? 'PM' : 'AM'),
    };

    let formattedDate = formatType;
    for (const [key, value] of Object.entries(formats)) {
        formattedDate = formattedDate.replace(key, value());
    }

    return formattedDate;
}

export function averageArr(arr: Array<number>) {
    return arr.reduce((a, b) => a + b) / arr.length;
}

export function formatTag(tag: string): string {
    return tag.split("__").map(s => toTitleCase(s.replaceAll("_", " ").replaceAll(" - ", " > "))).join(" > ")
}

export function removeTagSections(tag: string, count: number, reverse = false): string {
    if (count === 0) {
        return tag
    }
    else {
        const firstIdx = tag.indexOf("__")
        const lastIdx = tag.lastIndexOf("__")
        if (firstIdx === -1 || lastIdx === -1) {
            return tag
        } else if (reverse) {
            return removeTagSections(tag.substring(0, lastIdx), count - 1, reverse)
        } else {
            return removeTagSections(tag.substring(firstIdx + 2), count - 1, reverse)
        }
    }
}

export function splitReverseJoin(pattern: string, joiner: string, s: string): string {
    return s.split(pattern).reverse().join(joiner)
}

export function toTitleCase(string: string): string {
    return string.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())
}

export function renameReasonForCall(reasonForCall: string[]): string {
    // Loop through each reason in reasonForCall and apply your renaming logic
    const renamedReasons = reasonForCall.map(reason => {
        // Your renaming logic here
        return formatTag(removeTagSections(reason, 1));
    });
    return renamedReasons.join('\n');
}


