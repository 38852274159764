import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import { Table, TableProps } from "react-bootstrap";

export interface SortableTableProps {
    tableProps?: TableProps,
    headers: Array<string>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Array<Array<any>>,
}

type SortStatus = { order: 'asc' | 'desc', index: number }

const SortableTable: React.FC<SortableTableProps> = ({ tableProps, ...props }) => {
    const [sortedData, setSortedData] = useState([...props.data].sort((a, b) => a[0] < b[0] ? -1 : 1))
    const [sortStatus, setSortStatus] = useState<SortStatus>({ order: 'asc', index: 0 })

    // Ensures function re-renders if props.data updates, since we're using sortedData in the final render
    useEffect(() => {
        setSortedData([...props.data].sort((a, b) => a[0] < b[0] ? -1 : 1))
        setSortStatus({ order: 'asc', index: 0 })
    }, [props.data])

    const sortTable = (index: number, order: 'asc' | 'desc') => {
        setSortStatus({ order: order, index: index })
        const sortModifier = order === 'asc' ? 1 : -1

        if (typeof sortedData[0][index] === 'string') {
            setSortedData([...sortedData].sort(
                (a, b) => (a[index] as string).toLowerCase().localeCompare(b[index].toLowerCase(), undefined, { numeric: true }) * sortModifier
            ))
        } else {
            setSortedData([...sortedData].sort(
                (a, b) => (a[index] === b[index] ? 0 : a[index] < b[index] ? -1 : 1) * sortModifier
            ))
        }
    }

    return (
        <Table {...tableProps}>
            <thead>
                <tr>
                    {props.headers.map((label, index) => (
                        <th key={`th-${index}`} style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); sortTable(index, index === sortStatus.index ? sortStatus.order === 'asc' ? 'desc' : 'asc' : 'asc') }}>
                            <span className="me-2 user-select-none">{label} </span><FontAwesomeIcon size="sm" icon={index === sortStatus.index ? sortStatus.order === 'asc' ? faSortUp : faSortDown : faSort}></FontAwesomeIcon>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {sortedData.map((rowData, index) => (
                    <tr key={`tr-${index}`}>
                        {rowData.map((data, index) => (
                            <td key={`td-${index}`}>{data}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default SortableTable;
