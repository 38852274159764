import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import JSZip from 'jszip';
import jsPDF from 'jspdf';
import FileSaver from 'file-saver';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import * as XLSX from 'xlsx';

interface AllCardsExportDropdownProps {
    supportedFileTypesForCharts: string[],
    supportedFileTypesForTables: string[],
    chartRefs: {
        totalSolveRateBarRef: React.MutableRefObject<ChartJSOrUndefined<'bar'> | undefined>,
        topSolvedIssuesPieRef: React.MutableRefObject<ChartJSOrUndefined<'pie'> | undefined>,
        topEscalatedPieRef: React.MutableRefObject<ChartJSOrUndefined<'pie'> | undefined>
    },
    tableRefs: Array<{
        title: string
        headers: Array<string>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: Array<Array<any>> | undefined
    }>,
    isLoading: boolean
}

const AllCardsExportDropdown: React.FC<AllCardsExportDropdownProps> = ({ supportedFileTypesForCharts, chartRefs, supportedFileTypesForTables, tableRefs, isLoading }) => {
    const [filesLoading, setFilesLoading] = useState(false);
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours() % 12 || 12}-${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    const createChartPDFs = (
        chartRefs: AllCardsExportDropdownProps['chartRefs'],
        supportedFileTypesForCharts: string[]
    ): Promise<Blob> => {
        const pdf = new jsPDF('landscape', 'pt', 'a4');
        const x = 40;
        let y = 40;

        // Set font style for chart titles
        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(16);
        pdf.setDrawColor(0, 0, 0);
        pdf.setFillColor(255, 255, 255);

        // Draw the first chart (bar chart) across the top of the page
        const barChartRef = chartRefs.totalSolveRateBarRef;
        if (barChartRef.current && supportedFileTypesForCharts.includes('pdf')) {
            const canvas = barChartRef.current.canvas as HTMLCanvasElement;
            const { width, height } = canvas;
            const aspectRatio = height / width;
            const availableWidth = pdf.internal.pageSize.getWidth() - x - 40;
            const availableHeight = pdf.internal.pageSize.getHeight() / 2 - y - 20;
            const scaledWidth = Math.min(availableWidth, availableHeight / aspectRatio);
            const scaledHeight = scaledWidth * aspectRatio;

            // Add title for bar chart
            const barChartTitle = 'Total Solve Rate';
            pdf.text(barChartTitle, x + scaledWidth / 2, y - 5, { align: 'center' });
            pdf.setLineWidth(1);
            const barChartTitleWidth = pdf.getTextWidth(barChartTitle) / pdf.internal.scaleFactor;
            pdf.line(x + scaledWidth / 2 - barChartTitleWidth / 2, y + 1, x + scaledWidth / 2 + barChartTitleWidth / 2, y + 1);

            const imgData = canvas.toDataURL('image/png', 1.0);
            pdf.addImage(imgData, 'PNG', x, y, scaledWidth, scaledHeight);
            y += scaledHeight + 40;
        }

        // Draw the second chart (topSolvedIssuesPieRef) on the left side of the bottom row
        const leftPieChartRef = chartRefs.topSolvedIssuesPieRef;
        if (leftPieChartRef.current && supportedFileTypesForCharts.includes('pdf')) {
            const canvas = leftPieChartRef.current.canvas as HTMLCanvasElement;
            const { width, height } = canvas;
            const aspectRatio = height / width;
            const availableWidth = (pdf.internal.pageSize.getWidth() / 2) - 20;
            const availableHeight = (pdf.internal.pageSize.getHeight() / 2) - 20;
            const scaledWidth = Math.min(availableWidth, availableHeight / aspectRatio);
            const scaledHeight = scaledWidth * aspectRatio;

            // Add title for left pie chart
            const leftPieChartTitle = 'Top Solved Issues';
            pdf.text(leftPieChartTitle, ((pdf.internal.pageSize.getWidth() / 2) / 2), (pdf.internal.pageSize.getHeight() / 2) + 10, { align: 'center' });
            // const leftPieChartTitleWidth = pdf.getStringUnitWidth(leftPieChartTitle) / pdf.internal.scaleFactor;
            pdf.setLineWidth(1);
            const titleWidth = pdf.getTextWidth(leftPieChartTitle) / pdf.internal.scaleFactor;
            const titleX = pdf.internal.pageSize.getWidth() / 4;
            const titleY = pdf.internal.pageSize.getHeight() / 2 + 10;
            pdf.line(titleX - titleWidth / 2, titleY + 2, titleX + titleWidth / 2, titleY + 2);

            const imgData = canvas.toDataURL('image/png', 1.0);
            pdf.addImage(imgData, 'PNG', 10, titleY + 10, scaledWidth, scaledHeight);
        }


        // Draw the third chart (topEscalatedPieRef) on the right side of the bottom row
        const rightPieChartRef = chartRefs.topEscalatedPieRef;
        if (rightPieChartRef.current && supportedFileTypesForCharts.includes('pdf')) {
            const canvas = rightPieChartRef.current.canvas as HTMLCanvasElement;
            const { width, height } = canvas;
            const aspectRatio = height / width;
            const availableWidth = (pdf.internal.pageSize.getWidth() / 2) - 20;
            const availableHeight = (pdf.internal.pageSize.getHeight() / 2) - 20;
            const scaledWidth = Math.min(availableWidth, availableHeight / aspectRatio);
            const scaledHeight = scaledWidth * aspectRatio;

            // Add title for left pie chart
            const rightPieChartTitle = 'Top Escalated Issues';
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageWidthQuartered = pageWidth / 4;
            const pageHeight = pdf.internal.pageSize.getHeight();

            pdf.text(rightPieChartTitle, pageWidthQuartered * 3, (pageHeight / 2) + 10, { align: 'center' });
            // const leftPieChartTitleWidth = pdf.getStringUnitWidth(leftPieChartTitle) / pdf.internal.scaleFactor;
            pdf.setLineWidth(1);
            const titleWidth = pdf.getTextWidth(rightPieChartTitle) / pdf.internal.scaleFactor;
            const titleX = pageWidthQuartered * 3;
            const titleY = pageHeight / 2 + 10;
            pdf.line(titleX - titleWidth / 2, titleY + 2, titleX + titleWidth / 2, titleY + 2);

            const imgData = canvas.toDataURL('image/png', 1.0);
            pdf.addImage(imgData, 'PNG', (pageWidth / 2) + 10, titleY + 10, scaledWidth, scaledHeight);
        }

        return Promise.resolve(pdf.output('blob'));
    };

    const createChartPNGs = async (
        chartRefs: AllCardsExportDropdownProps['chartRefs'],
        supportedFileTypesForCharts: string[]
    ): Promise<{ [fileName: string]: Blob }> => {
        const images: { [fileName: string]: Blob } = {};
        const barChartRef = chartRefs.totalSolveRateBarRef;
        if (barChartRef.current && supportedFileTypesForCharts.includes('png')) {
            const canvas = barChartRef.current.canvas as HTMLCanvasElement;
            // const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

            // Create new canvas with title and chart
            const newCanvas = document.createElement('canvas');
            newCanvas.width = canvas.width;
            newCanvas.height = canvas.height + 40; // Add space for title
            const newCtx = newCanvas.getContext('2d') as CanvasRenderingContext2D;
            newCtx.fillStyle = 'white'; // Set background color
            newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height); // Fill background with white

            // Draw title on new canvas
            const title = 'Total Solve Rate';
            newCtx.fillStyle = 'black'; // Set font color to black
            newCtx.font = 'bold 16px sans-serif';
            newCtx.textAlign = 'center';
            newCtx.fillText(title, newCanvas.width / 2, 20);

            // Draw line below title on new canvas
            const lineWidth = newCtx.measureText(title).width; // Get width of title
            newCtx.beginPath();
            newCtx.moveTo((newCanvas.width / 2) - (lineWidth / 2), 22); // Start line at center of title
            newCtx.lineTo((newCanvas.width / 2) + (lineWidth / 2), 22); // End line at center of title
            newCtx.lineWidth = 2;
            newCtx.strokeStyle = 'black';
            newCtx.stroke();

            // Draw chart on new canvas
            newCtx.drawImage(canvas, 0, 40, canvas.width, canvas.height);

            // Create image blob from new canvas
            await new Promise<void>((resolve) => {
                newCanvas.toBlob((blob) => {
                    if (blob) {
                        images[`Total Solve Rate ${formattedDate}.png`] = blob;
                    }
                    resolve();
                }, 'image/png', 1.0);
            });
        }

        // Top Solved Issues pie chart
        const leftPieChartRef = chartRefs.topSolvedIssuesPieRef;
        if (leftPieChartRef.current && supportedFileTypesForCharts.includes('png')) {
            const canvas = leftPieChartRef.current.canvas as HTMLCanvasElement;

            // Create new canvas with title and chart
            const newCanvas = document.createElement('canvas');
            newCanvas.width = canvas.width;
            newCanvas.height = canvas.height + 40; // Add space for title
            const newCtx = newCanvas.getContext('2d') as CanvasRenderingContext2D;
            newCtx.fillStyle = 'white'; // Set background color
            newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height); // Fill background with white

            // Draw title on new canvas
            const title = 'Top Solved Issues';
            newCtx.fillStyle = 'black'; // Set font color to black
            newCtx.font = 'bold 16px sans-serif';
            newCtx.textAlign = 'center';
            newCtx.fillText(title, newCanvas.width / 2, 20);
            // Draw line below title on new canvas
            const lineWidth = newCtx.measureText(title).width; // Get width of title
            newCtx.beginPath();
            newCtx.moveTo((newCanvas.width / 2) - (lineWidth / 2), 22); // Start line at center of title
            newCtx.lineTo((newCanvas.width / 2) + (lineWidth / 2), 22); // End line at center of title
            newCtx.lineWidth = 2;
            newCtx.strokeStyle = 'black';
            newCtx.stroke();


            // Draw chart on new canvas
            newCtx.drawImage(canvas, 0, 40, canvas.width, canvas.height);

            // Create image blob from new canvas
            await new Promise<void>((resolve) => {
                newCanvas.toBlob((blob) => {
                    if (blob) {
                        images[`Top Solved Issues ${formattedDate}.png`] = blob;
                    }
                    resolve();
                }, 'image/png', 1.0);
            });
        }

        // Top Escalated Issues pie chart
        const rightPieChartRef = chartRefs.topEscalatedPieRef;
        if (rightPieChartRef.current && supportedFileTypesForCharts.includes('png')) {
            const canvas = rightPieChartRef.current.canvas as HTMLCanvasElement;

            // Create new canvas with title and chart
            const newCanvas = document.createElement('canvas');
            newCanvas.width = canvas.width;
            newCanvas.height = canvas.height + 40; // Add space for title
            const newCtx = newCanvas.getContext('2d') as CanvasRenderingContext2D;
            newCtx.fillStyle = 'white'; // Set background color
            newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height); // Fill background with white

            // Draw title on new canvas
            const title = 'Top Escalated Issues';
            newCtx.fillStyle = 'black'; // Set font color to black
            newCtx.font = 'bold 16px sans-serif';
            newCtx.textAlign = 'center';
            newCtx.fillText(title, newCanvas.width / 2, 20);
            // Draw line below title on new canvas
            const lineWidth = newCtx.measureText(title).width; // Get width of title
            newCtx.beginPath();
            newCtx.moveTo((newCanvas.width / 2) - (lineWidth / 2), 22); // Start line at center of title
            newCtx.lineTo((newCanvas.width / 2) + (lineWidth / 2), 22); // End line at center of title
            newCtx.lineWidth = 2;
            newCtx.strokeStyle = 'black';
            newCtx.stroke();


            // Draw chart on new canvas
            newCtx.drawImage(canvas, 0, 40, canvas.width, canvas.height);

            // Create image blob from new canvas
            await new Promise<void>((resolve) => {
                newCanvas.toBlob((blob) => {
                    if (blob) {
                        images[`Top Escalated Issues ${formattedDate}.png`] = blob;
                    }
                    resolve();
                }, 'image/png', 1.0);
            });
        }

        return images;
    };


    const createTablesXLSX = (
        tableRefs: AllCardsExportDropdownProps['tableRefs'],
        supportedFileTypesForTables: string[]
    ): Promise<Blob> => {
        const wb = XLSX.utils.book_new();

        tableRefs.forEach((tableRef, idx) => {
            if (tableRef.data && supportedFileTypesForTables.includes('xlsx')) {
                const data = [tableRef.headers, ...tableRef.data];
                const ws = XLSX.utils.aoa_to_sheet(data);
                const sheetName = idx === 0 ? 'Tickets - Escalated' : 'Tickets - Solved Remotely';

                // Set column widths based on the maximum length of the data in each column
                const columnWidths = data.reduce((acc, row) => {
                    row.forEach((cell, colIndex) => {
                        const cellLength = typeof cell === 'string' ? cell.length : 0;
                        if (!acc[colIndex] || cellLength > acc[colIndex]) {
                            acc[colIndex] = cellLength;
                        }
                    });
                    return acc;
                }, []);
                ws['!cols'] = columnWidths.map((width) => ({ width }));

                XLSX.utils.book_append_sheet(wb, ws, sheetName);
            }
        });

        // Add Solve Rate Percentages sheet
        const totalRows = tableRefs.reduce((acc, tableRef) => acc + (tableRef.data?.length ?? 0), 0);

        const escalatedPct = (tableRefs[0]?.data?.length || 0) / totalRows * 100;
        const notEscalatedPct = (tableRefs[1]?.data?.length || 0) / totalRows * 100;

        const ticketsByVia: { [via: string]: { count: number } } = {};
        tableRefs.forEach((tableRef) => {
            if (tableRef.data) {
                tableRef.data.forEach((row) => {
                    const via = row[1] || 'Other';
                    ticketsByVia[via] = ticketsByVia[via] || { count: 0 };
                    ticketsByVia[via].count += 1;
                });
            }
        });
        // Calculate percentages for each "Via" type
        const viaRows: [string, number][] = Object.entries(ticketsByVia).map(([via, { count }]) => [`Total - ${via}s`, count]);

        const viaEscalated: [string, number][] = Object.entries(ticketsByVia).map(([via, { count }]) => {
            const filteredData = tableRefs[0]?.data?.filter(row => row[1] === via);
            const percentage = filteredData ? filteredData.length / count * 100 : 0;
            return [`${via}s - Escalated`, percentage];
        });

        const viaNotEscalated: [string, number][] = Object.entries(ticketsByVia).map(([via, { count }]) => {
            const filteredData = tableRefs[1]?.data?.filter(row => row[1] === via);
            const percentage = filteredData ? filteredData.length / count * 100 : 0;
            return [`${via}s - Solved Remotely`, percentage];
        });

        const viaRowsWithPercentages: [string, number, string, string][] = viaRows.map(([via, count], i) => [
            via,
            count,
            `${viaEscalated[i][1].toFixed(0)}%`,
            `${viaNotEscalated[i][1].toFixed(0)}%`,
        ]);

        // Append the "Solve Rate Percentages" sheet to the workbook
        const solveRateWs = XLSX.utils.aoa_to_sheet([
            ['Type', 'Total', 'Escalated %', 'Solved Remotely %'],
            ['Total Tickets', totalRows ?? 0, `${escalatedPct.toFixed(0)}%`, `${notEscalatedPct.toFixed(0)}%`],
            ['Total - Escalated', `${tableRefs[0]?.data?.length ?? 0}`, ``, ``],
            ['Total - Solved Remotely', `${tableRefs[1]?.data?.length ?? 0}`, ``, ``],
            ['', '', '', ''],
            ...viaRowsWithPercentages,
        ]);

        solveRateWs['!cols'] = [
            { width: 20 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
        ];

        XLSX.utils.book_append_sheet(wb, solveRateWs, 'Solve Rate Percentages');

        return new Promise<Blob>((resolve) => {
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            resolve(blob);
        });
    }

    const createTablesCSV = (tableRefs: AllCardsExportDropdownProps['tableRefs'], supportedFileTypesForTables: string[]): Promise<Blob> => {
        let csvContent = '';

        tableRefs.forEach((tableRef, idx) => {
            if (tableRef.data && supportedFileTypesForTables.includes('csv')) {
                const data = [tableRef.headers, ...tableRef.data];
                const tableName = idx === 0 ? 'Tickets - Escalated' : 'Tickets - Solved Remotely';
                csvContent += `${tableName}\r\n${data.map(row => row.map(field => typeof field === 'string' ? `"${field}"` : field).join(',')).join('\r\n')}\r\n\r\n`;
            }
        });

        return Promise.resolve(new Blob([csvContent], { type: 'text/csv' }));
    };




    const handleExportAll = async () => {
        setFilesLoading(true);
        try {
            const [chartsPDF, chartsPNGs, tablesXLSX, tablesCSV] = await Promise.all([
                createChartPDFs(chartRefs, supportedFileTypesForCharts),
                createChartPNGs(chartRefs, supportedFileTypesForCharts),
                createTablesXLSX(tableRefs, supportedFileTypesForTables),
                createTablesCSV(tableRefs, supportedFileTypesForTables),
            ]);

            const zip = new JSZip();

            // Add PDF file
            const pdfFileName = `Charts ${formattedDate}.pdf`;
            const pdfFile = new File([chartsPDF], pdfFileName, { type: chartsPDF.type });
            zip.file(pdfFile.name, pdfFile);

            // Add chart image files
            Object.entries(chartsPNGs).forEach(([fileName, file]) => {
                zip.file(fileName, file);
            });

            // Add XLSX file
            const xlsxFileName = `Ticket Data ${formattedDate}.xlsx`;
            const xlsxFile = new File([tablesXLSX], xlsxFileName, { type: tablesXLSX.type });
            zip.file(xlsxFile.name, xlsxFile);

            // Add CSV file
            const csvFileName = `Ticket Data ${formattedDate}.csv`;
            const csvFile = new File([tablesCSV], csvFileName, { type: tablesCSV.type });
            zip.file(csvFile.name, csvFile);

            const content = await zip.generateAsync({ type: 'blob' });


            FileSaver.saveAs(content, `Ticket Data And Charts ${formattedDate}.zip`);

        } catch (err) {
            alert('Error exporting files.');
        } finally {
            setFilesLoading(false);
        }
    };

    return (
        <Button id="all-cards-export-dropdown"
            variant="outline-primary"
            disabled={filesLoading || isLoading}
            onClick={handleExportAll}
        >
            Export All
        </Button>
    );
};

export default AllCardsExportDropdown;
