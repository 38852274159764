import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
// import Sidebar from './Sidebar';

interface CenterProps {

}

const Center: React.FC<CenterProps> = () => {
    return (
        <Row className="flex-grow-1">
            {/* <Col sm="auto" className="p-0 bg-secondary overflow-hidden" style={{ height: "94vh", maxHeight: "94vh" }}>
                <Sidebar></Sidebar>
            </Col> */}
            <Col sm={true} className="overflow-auto d-flex p-2" style={{ height: "94vh", maxHeight: "94vh" }}>
                <Outlet /> {/* Render child route elements */}
            </Col>
        </Row>
    );
}

export default Center;