//React Imports
import React from 'react';

//React Router Imports
import { Navigate } from 'react-router-dom';

//Bootstrap Imports
import { Card, Col, Row } from 'react-bootstrap';

//Firebase Imports

//Component Imports
import FBAuthContext from '../../contexts/FBAuthContext';
import ParasolLogo from '../../images/ParasolLogo';

//FontAwesome Imports
import LoginForm from './LoginForm';
import FederatedLogin from './FederatedLogin';

interface LoginProps {
}

interface LoginState {
}

class Login extends React.Component<LoginProps, LoginState> {
    //declare static context for this class component
    static contextType = FBAuthContext;

    constructor(props: LoginProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <FBAuthContext.Consumer>
                {({ fbAuth, fbUser }) => {
                    if (fbUser) {
                        return (<Navigate to="/" replace={true} />)
                    }
                    else {
                        return (
                            <Row className="bg-primary d-flex justify-content-center align-items-center overflow-hidden m-0" style={{ height: "100vh", width: "100vw"}}>
                                <Col xs={12} sm={11} md={10} lg={5} xl={4} xxl={3}>
                                    <Card className="w-100">
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title className="d-flex flex-column justify-content-center align-items-center fs-1">
                                                <ParasolLogo className="m-2" fill="#67A2B9" stroke=" #67A2B9" width="256px" strokeWidth="1" />
                                                <h1 className="my-2">Reporting Portal</h1>
                                            </Card.Title>
                                            <LoginForm fbAuth={fbAuth}></LoginForm>
                                            <hr className="hr-text" data-content="OR" />
                                            <FederatedLogin fbAuth={fbAuth}></FederatedLogin>
                                        </Card.Body>
                                        {/* <Card.Footer className="w-100 d-flex justify-content-around align-items-center">
                                        <Button variant="link">Sign up</Button>
                                        <Button variant="link">Forgot password</Button>
                                    </Card.Footer> */}
                                    </Card>
                                </Col>
                            </Row>
                        )
                    }
                }}
            </FBAuthContext.Consumer>
        )
    }
}

export default Login;