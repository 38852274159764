//React imports
import React, { useState } from 'react'

//React Router imports
import { Route, Routes } from 'react-router-dom'

//Componenet imports
import Login from './login/Login';
import Home from './homepage/Home';
import Reporting from './reporting/Reporting';

//Context imports
import { FBAuthProvider } from '../contexts/FBAuthContext';

//Firebase imports
import { initializeApp } from "firebase/app";

interface SelectedOption {
	dName: string;
	dsk: string;
}

const App: React.FC = () => {
	const firebaseConfig = {
		apiKey: "AIzaSyDjBQpX-11H3XP7IF4eqc9TI49Ku7ffmds",
		authDomain: "parasol-dealer-portal.firebaseapp.com",
		databaseURL: "https://parasol-dealer-portal.firebaseio.com",
		projectId: "parasol-dealer-portal",
		storageBucket: "parasol-dealer-portal.appspot.com",
		messagingSenderId: "445399314240",
		appId: "1:445399314240:web:1f3758f1abee148a6514a5",
		measurementId: "G-ZE82ERHG99"
	}

	const app = initializeApp(firebaseConfig)
	const [selectedOptionApp, setSelectedOptionApp] = useState<SelectedOption>({ dName: "", dsk: "" });
	return (
		<FBAuthProvider fbApp={app}>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/" element={<Home setSelectedOptionApp={setSelectedOptionApp} selectedOptionApp={selectedOptionApp} />}>
					<Route path="" element={<Reporting fbApp={app} selectedOptionApp={selectedOptionApp} />} />
				</Route>
			</Routes>
		</FBAuthProvider>
	)
}

export default App;