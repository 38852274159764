//React imports
import React from 'react';

//React Router imports
import { useNavigate } from 'react-router-dom';

//React Bootstrap imports
import { Button } from 'react-bootstrap';

//Firebase imports
import { Auth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

//FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

interface FederatedLoginProps {
    fbAuth: Auth | null
}

const FederatedLogin: React.FC<FederatedLoginProps> = (props) => {
    const navigate = useNavigate()

    const signInWithGoogle = async () => {
        const googleProvider = new GoogleAuthProvider();
        try {
            if (props.fbAuth) {
                await signInWithPopup(props.fbAuth, googleProvider)
                return navigate('/')
            }
            else {
                throw Error("Firebase auth not initialized")
            }
        } catch (err) {
            console.error(err)
            alert(err)
        }
    }

    return (
        <div className="d-flex flex-column">
            <Button className="my-1 w-100" onClick={signInWithGoogle}>
                <FontAwesomeIcon className="me-2" icon={faGoogle}></FontAwesomeIcon>
                Login with Google
            </Button>
            <small className="text-muted">
                Please use the same Google account as the Dealer Portal.
            </small>
        </div>
    );
}

export default FederatedLogin;