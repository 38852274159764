//React imports
import * as React from 'react';

//React Bootstrap imports
import { Card } from 'react-bootstrap';
// Dropdown, DropdownButton 

// //Font awesome imports
// import { faFileExport } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type DataCardProps = {
    body: string | React.ReactNode,
    header: string | React.ReactNode,
    title?: string,
    maxHeight?: string | number | undefined
    overflow?: "visible" | "hidden" | "clip" | "scroll" | "auto"
}

const DataCard: React.FunctionComponent<DataCardProps> = (props: DataCardProps) => {
    return (
        <Card className="h-100 w-100">
            <Card.Header className="w-100 d-flex justify-content-between align-items-center">
                {props.header}
                {/* <DropdownButton variant="outline-primary" size="sm" id="dropdown-basic-button" title={<FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>}>
                    <Dropdown.Item>PDF</Dropdown.Item>
                    <Dropdown.Item>CSV</Dropdown.Item>
                    <Dropdown.Item>XLSX</Dropdown.Item>
                    <Dropdown.Item>Format 4</Dropdown.Item>
                    <Dropdown.Item>Format 5</Dropdown.Item>
                </DropdownButton> */}
            </Card.Header>
            <Card.Body style={{ maxHeight: props.maxHeight ? props.maxHeight : 'auto', overflow: props.overflow ? props.overflow : "auto" }}>
                {props.title ? <Card.Title>{props.title}</Card.Title> : ''}
                {typeof props.body === 'string' ? <Card.Text>{props.body}</Card.Text> : props.body}
            </Card.Body>
        </Card>
    );
}

export default DataCard;