/* eslint-disable @typescript-eslint/no-explicit-any */
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth, User } from 'firebase/auth';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Row, Col, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ParasolLogo from '../../images/ParasolLogo';
import FBAuthContext from "../../contexts/FBAuthContext";
//Firebase Hook imports
import { getFunctions } from 'firebase/functions';
import { useHttpsCallable } from 'react-firebase-hooks/functions'


interface TopBarProps {
    fbAuth: Auth | null
    fbUser: User | null
    // eslint-disable-next-line @typescript-eslint/ban-types
    setSelectedOptionApp: Function
}
interface MsaOption {
    result: Array<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any;
        dName: string;
        dsk: string;
        ovrcID: string;
    }>
}


interface SelectedOption {
    dName: string;
    dsk: string;
}

const TopBar: React.FC<TopBarProps> = ({ fbAuth, fbUser, setSelectedOptionApp }) => {
    const navigate = useNavigate();
    const { userSleeve } = useContext(FBAuthContext);
    const [msaOptions, setMsaOptions] = useState<{ dName: string; dsk: string }[]>([]);


    const fbFunctionsInstance = getFunctions()
    const [dealerPortalMsa] = useHttpsCallable<object, MsaOption>(fbFunctionsInstance, 'dealerPortal-msa');

    useEffect(() => {
        dealerPortalMsa({
            uid: fbUser?.uid,
            dsk: userSleeve?.dsk
        }).then((result) => {
            if (result) {
                if (Array.isArray(result.data.result)) {
                    const options = result.data.result.map(option => ({
                        dName: (option && option.dName) ? option.dName : option.dealerName,
                        dsk: option.dsk,
                    }));
                    setMsaOptions(options);
                } else {
                    if (typeof result.data === 'object' && result.data !== null) {
                        const options = Object.entries(result.data).map(([, option]) => ({
                            dName: (option as any).dName ? (option as any).dName : (option as any).dealerName,
                            dsk: (option as any).dsk,
                        }));
                        setMsaOptions(options);
                    }
                }
            }
        }).catch((error: Error) => console.error(error));
    }, [dealerPortalMsa, fbUser?.uid, userSleeve]);

    const uniqueMsaOptions = useMemo<{ dName: string; dsk: string }[]>(
        () =>
            msaOptions.map(
                (option: { dName: string; dsk: string }): { dName: string; dsk: string } => ({
                    dName: option.dName,
                    dsk: option.dsk,
                })
            ),
        [msaOptions]
    );

    const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
        uniqueMsaOptions.length > 0 ? uniqueMsaOptions[0] : null
    );

    useEffect(() => {
        setSelectedOption(selectedOption);
        setSelectedOptionApp(selectedOption);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSelectedOptionApp, selectedOption]);

    useEffect(() => {
        setSelectedOption(uniqueMsaOptions.length > 0 ? uniqueMsaOptions[0] : null);
        setSelectedOptionApp(uniqueMsaOptions.length > 0 ? uniqueMsaOptions[0] : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniqueMsaOptions]);

    const handleOptionSelect = (eventKey: string | null) => {
        const selVal = eventKey ? uniqueMsaOptions.find((option) => option.dsk === eventKey) : null;
        if (selVal) {
            setSelectedOption(selVal);
        }
    };

    return (
        <Row className="bg-primary px-2 py-2 d-inline-flex justify-content-between align-items-center" style={{ width: "100vw" }}>
            <Col xs="auto" className="d-flex align-items-center pe-0 pe-sm-2">
                <span className="d-none d-sm-block" style={{ maxHeight: '96px' }}><ParasolLogo fill="white" stroke="white" strokeWidth="1" width="96px" height="100%" /></span>
                <span className="d-auto d-sm-none" style={{ maxHeight: '96px' }}><ParasolLogo fill="white" stroke="white" strokeWidth="1" width="64px" height="100%" /></span>
                <span className="text-white fs-3 ps-2">Reporting Portal</span>
            </Col>
            <Col xs="auto" className="h-100 d-flex align-items-center ps-0 ps-sm-2">
                <span className="d-none d-sm-flex flex-column align-items-end pe-3 text-white lh-sm">
                    <span>{fbUser?.displayName}</span>
                    {userSleeve && userSleeve.dealerName ? (<span>{
                        <Dropdown defaultValue={uniqueMsaOptions.length > 0 ? uniqueMsaOptions[0].dsk : undefined}>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                {selectedOption ? selectedOption.dName : "Select an option"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "scroll" }}>
                                {uniqueMsaOptions.map((option) => (
                                    <Dropdown.Item
                                        key={option.dsk}
                                        eventKey={option.dsk}
                                        active={selectedOption?.dsk === option.dsk}
                                        onClick={() => handleOptionSelect(option.dsk)}
                                    >
                                        {option.dName}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>


                    }</span>) : (<span>No Assigned Dealer</span>)}
                </span>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        <FontAwesomeIcon className="text-white" style={{ aspectRatio: 1 }} size="lg" icon={faUser} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item className="d-auto d-sm-none">
                            <span>{fbUser?.displayName}, </span>
                            {userSleeve && userSleeve.dealerName ? (<span>
                                <Dropdown defaultValue={uniqueMsaOptions.length > 0 ? uniqueMsaOptions[0].dsk : undefined}>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        {selectedOption ? selectedOption.dName : "Select an option"}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {uniqueMsaOptions.map((option) => (
                                            <Dropdown.Item
                                                key={option.dsk}
                                                eventKey={option.dsk}
                                                active={selectedOption?.dsk === option.dsk}
                                                onClick={() => handleOptionSelect(option.dsk)}
                                            >
                                                {option.dName}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>) : (<span>No Assigned Dealer</span>)}
                        </Dropdown.Item>
                        {/* <Link to="/profile" className="text-decoration-none text-reset"><Dropdown.Item as="div">My Profile</Dropdown.Item></Link> */}
                        <Dropdown.Item as="button" onClick={() => { fbAuth?.signOut(); navigate('/login') }}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

            </Col>
        </Row>
    );
}

export default TopBar;