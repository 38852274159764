/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import SortableTable from '../SortableTable';
import CardExportDropdown from './CardExportDropdown';
import DataCard from './DataCard';

interface ReportingTablesProps {
    tables: Array<{
        setTable: React.Dispatch<React.SetStateAction<{
            title: string;
            headers: Array<string>;
            data: any[][] | undefined;
        }>>,
        table: {
            title: string
            headers: Array<string>,
            data: Array<Array<any>> | undefined
        }
    }>,
    isLoading: boolean
}

const ReportingTables: React.FC<ReportingTablesProps> = (props) => {

    return (
        <Row>
            {props.tables.map(({ table, setTable }, index) => {
                if (table.data === undefined) {
                    return ""
                }
                return (
                    <Col xs={12} xxl={6} className="pb-4" key={`report-table-${index}`}>
                        <DataCard
                            header={
                                <div className="d-inline-flex justify-content-between align-items-center w-100">
                                    {`${table.title} (${table.data.length})`}
                                    <CardExportDropdown
                                        exportOptions={['XLSX', 'CSV', 'ALL']}
                                        tableData={table.data}
                                        supportedFileTypes={['XLSX', 'CSV', 'ALL']}
                                        fileName={String(table.title).includes('Tickets - Escalated') ? "Escalated Tickets Table" : "Solved Remotely Tickets Table"}
                                        headers={table.headers}
                                        disabled={props.isLoading}
                                    />
                                </div>
                            }
                            maxHeight="40vh"
                            body={
                                props.isLoading ?
                                    <div className="w-100 h-100 d-flex justify-content-center align-items-center"><Spinner animation="border" variant="primary" /></div> :
                                    <SortableTable
                                        tableProps={{ striped: true, responsive: true }}
                                        headers={table.headers}
                                        data={table.data}
                                    />
                            }
                        />
                    </Col>
                );
            })}
        </Row>
    );
}

export default ReportingTables;
