//React Imports
import React from "react";

//Bootstrap Imports
// import { Nav } from "react-bootstrap;"
import { Container } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
import { Navigate } from "react-router-dom";

//Component Imports
import FBAuthContext from '../../contexts/FBAuthContext'

//Firebase Imports

//FontAwesome Imports
// import { faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons";
import TopBar from "./TopBar";
import Center from "./Center";

interface HomeProps {
    // eslint-disable-next-line @typescript-eslint/ban-types
    setSelectedOptionApp: Function
    selectedOptionApp: SelectedOption
}
interface SelectedOption {
    dName: string;
    dsk: string;
}
interface HomeState {
}

class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <FBAuthContext.Consumer>
                {({ fbAuth, fbUser }) => {
                    if (!fbUser) {
                        return (<Navigate to="/login" replace={true} />)
                    }
                    else {
                        return (
                            <Container fluid>
                                <TopBar fbAuth={fbAuth} fbUser={fbUser} setSelectedOptionApp={this.props.setSelectedOptionApp} />
                                <Center></Center>
                            </Container >
                        )
                    }
                }}
            </FBAuthContext.Consumer>
        )
    }
}

export default Home;