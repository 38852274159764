/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useContext } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import FBAuthContext from '../../contexts/FBAuthContext';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from '../../react-app-env';
import AllCardsExportDropdown from './AllCardsExportDropdown';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { formatDate } from '../../helpers';
import { clientLoaderResult } from '../../react-app-env';

interface ReportingFiltersProps {
    usersClients: clientLoaderResult['returnerClients'];
    initialStartDate: Date,
    initialEndDate: Date,
    isLoading: boolean,
    onSubmit: Function,
    selectedClients: Array<Option>,
    setSelectedClients: Function,
    chartRefs: {
        totalSolveRateBarRef: React.MutableRefObject<ChartJSOrUndefined<'bar'> | undefined>,
        topSolvedIssuesPieRef: React.MutableRefObject<ChartJSOrUndefined<'pie'> | undefined>,
        topEscalatedPieRef: React.MutableRefObject<ChartJSOrUndefined<'pie'> | undefined>
    },
    tables: Array<{
        title: string
        headers: Array<string>,
        data: Array<Array<any>> | undefined
    }>
    selectedOptionApp: SelectedOption
}

interface SelectedOption {
    dName: string;
    dsk: string;
}

interface customOption {
    ovrcAccName: string;
    ovrcAccountID: string;
    ovrcLocationID: string;
    customOption?: boolean;
}

const ReportingFilters: React.FC<ReportingFiltersProps> = (props) => {
    const { fbUser } = useContext(FBAuthContext);
    const MAX_DAY_DIFF = 373;

    const [startDate, setStartDate] = useState(props.initialStartDate);
    const [startDateValidation, setStartDateValidation] = useState<{ isInvalid: boolean, msg: string }>({ isInvalid: false, msg: "" });
    const [endDate, setEndDate] = useState(props.initialEndDate);
    const [endDateValidation, setEndDateValidation] = useState<{ isInvalid: boolean, msg: string }>({ isInvalid: false, msg: "" });
    const [tmpClientHolder, setTmpClientHolder] = useState<Option[]>([]);

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const form = e.currentTarget;
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (form.checkValidity() === false || dayDiff > MAX_DAY_DIFF) {
            e.stopPropagation();
            setStartDateValidation({ isInvalid: true, msg: `Max range is ${MAX_DAY_DIFF} days` });
            setEndDateValidation({ isInvalid: true, msg: `Max range is ${MAX_DAY_DIFF} days` });
        } else {
            setStartDateValidation({ isInvalid: false, msg: "" });
            setEndDateValidation({ isInvalid: false, msg: "" });
            props.setSelectedClients(tmpClientHolder);
            props.onSubmit(fbUser, startDate, endDate, tmpClientHolder, props.selectedOptionApp.dsk, props.selectedOptionApp.dName);
        }
    };

    const handleSelectClients = () => {
        props.setSelectedClients(tmpClientHolder);
    };

    const handleNewOption = (inputValue: string): customOption => {
        return {
            ovrcAccName: inputValue,
            ovrcAccountID: "",
            ovrcLocationID: "",
            customOption: true
        };
    };

    return (
        <Row className="pb-4 align-items-center">
            <Col xs={true} sm="auto" xxl={true} className="d-flex justify-content-center justify-content-sm-start pb-2 pb-sm-0">
                <AllCardsExportDropdown supportedFileTypesForCharts={["pdf", "png"]} isLoading={props.isLoading} chartRefs={props.chartRefs} tableRefs={props.tables} supportedFileTypesForTables={["xlsx", "csv"]} />
            </Col>

            <Col xs="auto" sm={true} xxl={6}>
                <Form noValidate onSubmit={handleFormSubmit}>
                    <Row>
                        <Form.Group as={Col} xs={12} lg={true} className="pb-2 pb-lg-0">
                            <Typeahead
                                id="selected-clients-typeahead"
                                labelKey="ovrcAccName"
                                options={props.usersClients.filter(client => client.ovrcAccName)}
                                placeholder="Type an account name or select an account..."
                                selected={tmpClientHolder}
                                onChange={(selected) => setTmpClientHolder(selected)}
                                onBlur={(e) => {
                                    const inputValue = e.target.value.trim();
                                    if (inputValue.length > 0) {
                                        setTmpClientHolder([...tmpClientHolder, handleNewOption(inputValue)]);
                                    }
                                }}
                                allowNew
                                newSelectionPrefix="Add new: "
                                multiple
                                renderMenuItemChildren={(option: any) => (
                                    <div key={option.ovrcAccountID || option.customOption ? option.ovrcAccName : 'custom'}>
                                        {option.ovrcAccName != null && <span>{option.ovrcAccName}</span>}
                                    </div>
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={true} lg={2} className="position-relative">
                            <Form.Control
                                type="date"
                                value={formatDate(startDate)}
                                onChange={(e) => {
                                    const date = new Date(e.target.value);
                                    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                                    setStartDate(date);
                                }}
                                required
                                isInvalid={startDateValidation.isInvalid}
                            />
                            <Form.Control.Feedback tooltip type="invalid">{startDateValidation.msg}</Form.Control.Feedback>
                        </Form.Group>
                        <Col className="d-flex align-items-center p-0" xs="auto">to</Col>
                        <Form.Group as={Col} xs={true} lg={2} className="position-relative invalid">
                            <Form.Control
                                type="date"
                                value={formatDate(endDate)}
                                onChange={(e) => {
                                    const date = new Date(e.target.value);
                                    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                                    setEndDate(date);
                                }}
                                required
                                isInvalid={endDateValidation.isInvalid}
                            />
                            <Form.Control.Feedback tooltip type="invalid">{endDateValidation.msg}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xs="auto" className="px-sm-0 px-md-2">
                            <Button variant="primary" type="submit" disabled={props.isLoading} onClick={() => {
                                handleSelectClients()
                                const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
                                const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

                                if (dayDiff > MAX_DAY_DIFF) {
                                    setStartDateValidation({ isInvalid: true, msg: `Max range is ${MAX_DAY_DIFF} days` })
                                    setEndDateValidation({ isInvalid: true, msg: `Max range is ${MAX_DAY_DIFF} days` })
                                } else {
                                    setStartDateValidation({ isInvalid: false, msg: "" })
                                    setEndDateValidation({ isInvalid: false, msg: "" })
                                    props.onSubmit(fbUser, startDate, endDate, tmpClientHolder, props.selectedOptionApp.dsk, props.selectedOptionApp.dName);
                                }
                            }}>
                                <FontAwesomeIcon icon={faArrowsRotate} />
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
}

export default ReportingFilters;
