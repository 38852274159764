/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect } from 'react';
import { FirebaseApp } from '@firebase/app';
import { ref, getDatabase, get, remove } from 'firebase/database';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { getFunctions } from 'firebase/functions';
import { formatDate } from '../helpers';
import { DashboardData, FilterCriteria } from '../react-app-env';
import { User } from 'firebase/auth';

async function fetchTicketData(database: any, ticketUrl: string, attempts = 3) {
    for (let i = 1; i <= attempts; i++) {
        const ticketDataRef = ref(database, ticketUrl);
        const ticketDataSnapshot = await get(ticketDataRef);
        const retData = ticketDataSnapshot.val();
        if (retData !== null) {
            await remove(ticketDataRef); // delete data at ticketUrl
            return retData;
        }
        await new Promise(resolve => setTimeout(resolve, 1000)); // wait 1 second before trying again
    }
    return null;
}

async function fetchAllTickets(fbApp: FirebaseApp, urls: Array<string>) {
    const database = getDatabase(fbApp);
    if (!urls || urls.length === 0) {
        return undefined;
    }

    try {
        const results = (await Promise.all(
            urls.map((ticketUrl: string | object) => {
                if (typeof ticketUrl === 'string') {
                    return fetchTicketData(database, ticketUrl)
                }
                return null
            })
        )).filter(data => data != null);

        const allTickets = results.reduce((acc, arr) => acc.concat(arr), []);
        return allTickets;
    } catch (error) {
        console.error(error);
    }
}

function applyFilters(tickets: any[], filterCriteria: FilterCriteria): any[] {
    if (!filterCriteria || !tickets) return tickets;
    // console.log(`tickets.length: ${tickets.length}`)
    return tickets.filter(ticket => {
        const { tags, reasonForCall } = filterCriteria;
        // console.log(`ticket keys: ${Object.keys(ticket)}`)
        // console.log(`ticket: ${JSON.stringify(ticket)}`)
        // Filter based on tags
        const hasUnwantedTag = ticket.tags?.some((tag: string) =>
            tags.unwantedValues.includes(tag) ||
            tags.excludeIfContains.some((substring: string) => tag.includes(substring))
        );
        if (hasUnwantedTag) return false;

        // Filter based on reason for call (custom fields)
        const reasonForCallField = ticket.custom_fields?.find((field: { id: number; value: string }) => field.id === 10101227468695 || field.id === 75534387);
        const reasonForCallValue = reasonForCallField?.value || '';
        const hasUnwantedReasonForCall = reasonForCall.unwantedValues.includes(reasonForCallValue) ||
            reasonForCall.excludeIfContains.some((substring: string) => reasonForCallValue.includes(substring));

        return !hasUnwantedReasonForCall;
    });
}

export function useDashboardData(fbApp: FirebaseApp):
    [DashboardData | undefined, typeof reload, boolean, Error | undefined] {
    const fbFunctionsInstance = getFunctions()

    type cloudFunctionParams = { currentUser: { uid: string | undefined }, dateRangeStart: string, dateRangeEnd: string, selectedClients: Array<object> }

    const [loadData, dataLoading, dataError] = useHttpsCallable<cloudFunctionParams, DashboardData>(fbFunctionsInstance, 'dealerReports-reportingDashboardLanding');
    const [loadFilterCriteria] = useHttpsCallable<any, FilterCriteria>(fbFunctionsInstance, 'shareableResources-firebaseRealtimeDatabase');

    const [fetchingTickets, setFetchingTickets] = useState(false)
    const [dashboardData, setDashboardData] = useState<DashboardData>()
    const [filterCriteria, setFilterCriteria] = useState<FilterCriteria | null>(null);

    useEffect(() => {
        const fetchCriteria = async () => {
            try {
                const response = await loadFilterCriteria({
                    dsk: 'loadingFilterables',
                    action: 'get',
                    keynode: '/reports/dealerReportingDashboard'
                });
                setFilterCriteria(response?.data || null);
            } catch (error) {
                console.error("Error fetching filter criteria:", error);
            }
        };

        fetchCriteria();
    }, [loadFilterCriteria]);

    const reload = useCallback((fbUser: User | null, startDate: Date, endDate: Date, selectedClients: Array<object>, msa: string, dealerName: string) => {
        const dataToSend = {
            currentUser: {
                uid: fbUser?.uid,
                msa: msa,
                dName: dealerName
            },
            dateRangeStart: formatDate(startDate),
            dateRangeEnd: formatDate(endDate),
            selectedClients: selectedClients
        }

        return loadData(dataToSend).then((res) => {
            if (res?.data.tickets !== undefined && res?.data.status === undefined) {
                if (typeof res?.data.tickets[0] === 'string') {
                    //if type is array of strings, then we have to load from realtime database
                    setFetchingTickets(true)
                    fetchAllTickets(fbApp, res.data.tickets as Array<string>).then((tickets) => {
                        const filteredTickets = applyFilters(tickets, filterCriteria || { reasonForCall: { excludeIfContains: [], unwantedValues: [] }, tags: { excludeIfContains: [], unwantedValues: [] } });
                        res.data.tickets = filteredTickets;
                        setDashboardData(res?.data);
                        setFetchingTickets(false);
                    })
                }
                else {
                    const filteredTickets = applyFilters(res.data.tickets, filterCriteria || { reasonForCall: { excludeIfContains: [], unwantedValues: [] }, tags: { excludeIfContains: [], unwantedValues: [] } });
                    res.data.tickets = filteredTickets;
                    setDashboardData(res?.data);
                }
            }
        })
    }, [fbApp, loadData, filterCriteria])

    return [dashboardData, reload, dataLoading || fetchingTickets, dataError]
}
