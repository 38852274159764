/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { clientLoaderResult, FBZenTicketResponse } from '../../react-app-env';
import CustomReportModal from './CustomReportModal';
import ReportingFilters from './ReportingFilters';
import ReportingTables from './ReportingTables';
import ReportingCharts from './ReportingCharts';
import FBAuthContext from '../../contexts/FBAuthContext';
import firebase from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import Database, { ref, getDatabase, onValue, off } from 'firebase/database';
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { useDashboardData } from "../../hooks/useDashboardData";
import { formatDate, renameReasonForCall } from '../../helpers';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';


interface ReportingProps {
    fbApp: firebase.FirebaseApp;
    selectedOptionApp: SelectedOption
}

interface SelectedOption {
    dName: string;
    dsk: string;
}

const Reporting: React.FC<ReportingProps> = ({ fbApp, selectedOptionApp }) => {
    /**Begin SECTION 1 */
    /** Chart Refs */
    const totalSolveRateBarRef = useRef<ChartJSOrUndefined<'bar'> | undefined>();
    const topSolvedIssuesPieRef = useRef<ChartJSOrUndefined<'pie'> | undefined>();
    const topEscalatedPieRef = useRef<ChartJSOrUndefined<'pie'> | undefined>();

    //Default ranges for date filters
    const today = new Date();
    const sevenDaysAgo = new Date(new Date().setDate(today.getDate() - 7));

    /** Context Hooks */
    const { fbUser, userSleeve } = useContext(FBAuthContext);

    /** State Hooks */
    const [showModal, setModal] = useState(false);
    const [usersCustomReports, setUsersCustomReports] = useState<Array<object>>([]);
    const [usersClients, setUsersClients] = useState<clientLoaderResult['returnerClients']>([]);
    const [selectedClients, setSelectedClients] = useState<Array<Array<string>>>([]);
    const [escalatedTickets, setEscalatedTickets] = useState<{ title: string, headers: Array<string>, data: any[][] | undefined }>({
        title: "Tickets - Escalated",
        headers: ['Ticket ID', "Via", "Reason for Contact", "Date Created (EST)"],
        data: []
    })
    const [notEscalatedTickets, setNotEscalatedTickets] = useState<{ title: string, headers: Array<string>, data: any[][] | undefined }>({
        title: "Tickets - Solved Remotely",
        headers: ['Ticket ID', "Via", "Reason for Contact", "Date Created (EST)"],
        data: []
    })

    /** Firebase Hooks */
    const fbFunctionsInstance = getFunctions()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadClientList, clientListLoading, clientListError] = useHttpsCallable<object, clientLoaderResult>(fbFunctionsInstance, 'dealerPortal-clientLoader');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadCustomReports, customReportsLoading, customReportsError] = useHttpsCallable<object, { status: string, userReports: Array<object>, dealerReports: Array<object> }>(fbFunctionsInstance, 'dealerReports-loadReportSettings');

    /** Custom Hooks */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dashboardData, loadDashboard, dataLoading, dataError] = useDashboardData(fbApp)

    /** Effect Hooks */
    /**
     * Calls the loadDashboard function once on component mount.
     * Ensures that the charts load with the default 7-day view.
     * Exhaustive deps disabled to ensure it only runs once on component mount or when the memoized function changes
     */

    useEffect(() => {
        if (selectedOptionApp && selectedOptionApp.dName !== '' && selectedOptionApp.dName !== 'Parasol') {
            loadClientList({
                uid: fbUser?.uid,
                reportingDSK: true,
                dName: selectedOptionApp.dName,
                dsk: userSleeve?.dsk,
                msa: selectedOptionApp.dsk
            }).then((res) => {
                // console.log(res);
                if (res?.data.returnerClients) {
                    // Sort the array based on the ovrcAccName or name field
                    res?.data.returnerClients.sort((a, b) => {
                        const nameA = a.ovrcAccName;
                        const nameB = b.ovrcAccName;
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0; // names must be equal
                    });
                    setUsersClients(res?.data.returnerClients);
                }
                else {
                    setUsersClients([]);
                }
            }).then(() => {
                // Load dashboard
                loadDashboard(fbUser, sevenDaysAgo, today, selectedClients, selectedOptionApp.dsk, selectedOptionApp.dName);
            });
        } else {
            setUsersClients([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptionApp]);

    useEffect(() => {
        const idToNameMap: { [key: number]: string } = {};
        (usersClients as Array<{ zendeskAccountOrgID: number, ovrcAccName: string }>).forEach(client => {
            idToNameMap[client.zendeskAccountOrgID] = client.ovrcAccName;
        });

        const filterTickets = (ticket: FBZenTicketResponse) => {
            const customField = ticket.custom_fields.find(field => field.id === 10099183634199);
            return customField?.value !== "10457450261655";
        };

        setEscalatedTickets({
            title: "Tickets - Escalated",
            headers: selectedClients.length !== 1 ? ['Ticket ID', "Via", "Reason for Contact", "OvrC Account Name", "Date Created (EST)"] : ['Ticket ID', "Via", "Reason for Contact", "Date Created (EST)"],
            data: (dashboardData?.tickets as Array<FBZenTicketResponse>)?.filter((ticket) => {
                if (!filterTickets(ticket)) return false;

                return (ticket.tags?.includes(`escalatetodealer`) || ticket.tags?.includes(`is_escalated`)) && !ticket.tags?.includes(`closed_by_merge`);
            }).map((ticket) => {
                const res = [];
                res.push(ticket.id);

                // Channel handling
                if (ticket.channel === "api") {
                    res.push("Auto Ticket");
                } else if (ticket.channel === "voice") {
                    res.push("Voice Ticket");
                } else if (ticket.channel === "email") {
                    res.push("Email Ticket");
                } else {
                    res.push("Other");
                }

                // Handling Reason for Contact for new and old ticket data
                const reasonForContactNew = ticket.custom_fields.find(({ id }) => id === 10101227468695)?.value as string || '';
                const reasonForContactOld = ticket.custom_fields.find(({ id }) => id === 75534387)?.value as string || '';
                const reasonArray = (reasonForContactNew || reasonForContactOld).split('|@|');
                res.push(renameReasonForCall(reasonArray));

                // Handling OvrC Account Name for new and old ticket data
                if (selectedClients.length !== 1) {
                    const orgIDNew = ticket.custom_fields.find(({ id }) => id === 10099183634199)?.value as number || null;
                    const ovrcNameOld = ticket.custom_fields.find(({ id }) => id === 360007221794)?.value as string || null;

                    const orgName = orgIDNew ? (idToNameMap[orgIDNew] || orgIDNew.toString()) : (ovrcNameOld);

                    res.push(orgName || '');
                }

                res.push(formatDate(ticket.created_at?.value, "MM/DD/YYYY hh:mm:ss A"));

                return res;
            })
        });

        setNotEscalatedTickets({
            title: "Tickets - Solved Remotely",
            headers: selectedClients.length !== 1 ? ['Ticket ID', "Via", "Reason for Contact", "OvrC Account Name", "Date Created (EST)"] : ['Ticket ID', "Via", "Reason for Contact", "Date Created (EST)"],
            data: (dashboardData?.tickets as Array<FBZenTicketResponse>)?.filter((ticket) => {
                if (!filterTickets(ticket)) return false;

                return !ticket.tags?.includes(`escalatetodealer`) && !ticket.tags?.includes(`is_escalated`) && !ticket.tags?.includes(`closed_by_merge`);
            }).map((ticket) => {
                const res = [];
                res.push(ticket.id);

                // Channel handling
                if (ticket.channel === "api") {
                    res.push("Auto Ticket");
                } else if (ticket.channel === "voice") {
                    res.push("Voice Ticket");
                } else if (ticket.channel === "email") {
                    res.push("Email Ticket");
                } else {
                    res.push("Other");
                }

                // Handling Reason for Contact for new and old ticket data
                const reasonForContactNew = ticket.custom_fields.find(({ id }) => id === 10101227468695)?.value as string || '';
                const reasonForContactOld = ticket.custom_fields.find(({ id }) => id === 75534387)?.value as string || '';
                const reasonArray = (reasonForContactNew || reasonForContactOld).split('|@|');
                res.push(renameReasonForCall(reasonArray));

                // Handling OvrC Account Name for new and old ticket data
                if (selectedClients.length !== 1) {
                    const orgIDNew = ticket.custom_fields.find(({ id }) => id === 10099183634199)?.value as number || null;
                    const ovrcNameOld = ticket.custom_fields.find(({ id }) => id === 360007221794)?.value as string || null;
                    const orgName = orgIDNew ? (idToNameMap[orgIDNew] || orgIDNew.toString()) : (ovrcNameOld);
                    res.push(orgName || '');
                }

                res.push(formatDate(ticket.created_at?.value, "MM/DD/YYYY hh:mm:ss A"));
                return res;
            })
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardData]);

    /** END SECTION 4 */

    /**Begin SECTION 5 */
    /**
     * Listens for changes in userReports key.
     * If a change is made to the reports list, the function updates state with the new list.
     */
    useEffect(() => {
        const reportingRef = ref(getDatabase(fbApp), `usersReports/${fbUser?.uid}/reporting`);
        const onCustomReportUpdate = (snapshot: Database.DataSnapshot) => {
            const data = snapshot.val();
            if (data) {
                if (data.userReports) {
                    setUsersCustomReports(data.userReports);
                }
                else {
                    setUsersCustomReports([]);
                }
            }
        };
        onValue(reportingRef, onCustomReportUpdate);
        return () => {
            off(reportingRef, undefined);
        };
    }, [fbApp, fbUser?.uid]);

    /** END SECTION 5 */

    return (
        <Container fluid className="p-2">
            <CustomReportModal usersClients={usersClients} usersCustomReports={usersCustomReports} show={showModal} onHide={() => { setModal(false); }} />
            <ReportingFilters tables={[escalatedTickets, notEscalatedTickets]} usersClients={usersClients} isLoading={dataLoading} initialStartDate={sevenDaysAgo} initialEndDate={today} selectedClients={selectedClients} setSelectedClients={setSelectedClients} onSubmit={loadDashboard} chartRefs={{ totalSolveRateBarRef: totalSolveRateBarRef, topSolvedIssuesPieRef: topSolvedIssuesPieRef, topEscalatedPieRef: topEscalatedPieRef }} selectedOptionApp={selectedOptionApp} />
            <ReportingCharts data={dashboardData} isLoading={dataLoading} chartRefs={{ totalSolveRateBarRef: totalSolveRateBarRef, topSolvedIssuesPieRef: topSolvedIssuesPieRef, topEscalatedPieRef: topEscalatedPieRef }} />
            <ReportingTables tables={[{ setTable: setEscalatedTickets, table: escalatedTickets }, { setTable: setNotEscalatedTickets, table: notEscalatedTickets }]} isLoading={dataLoading} />
        </Container>
    );
}

export default Reporting;