// React Imports
import ReactDOM from 'react-dom/client';

// Analytics Imports
import reportWebVitals from './reportWebVitals';

// Bootstrap Imports
import './styles/theme.scss';
import { ThemeProvider } from 'react-bootstrap';

// React Router Imports
import { BrowserRouter } from 'react-router-dom'

//Style Imports
import './styles/theme.scss';

// Component Imports
import App from './components/App'

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<ThemeProvider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
