import { faEye, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth, signInWithEmailAndPassword, } from 'firebase/auth';
import React, { useState } from 'react';
import { Form, FloatingLabel, InputGroup, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface LoginFormProps {
    fbAuth: Auth | null
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    
    const clearLoginInfo = () => {
        setEmail('')
        setPassword('')
    }

    const handleSubmit = (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        signIn()
    }

    const signIn = async () => {
        try {
            if(props.fbAuth) {
                await signInWithEmailAndPassword(props.fbAuth, email, password).then(clearLoginInfo)
                navigate('/')
            }
            else {
                throw Error("Firebase auth not initialized")
            }
        } catch (err) {
            clearLoginInfo()
            console.error(err)
            alert(err)
        }
    }

    return (
        <Form className="my-1" onSubmit={handleSubmit}>
            <Form.Group className="pb-3">
                <FloatingLabel controlId="floatingEmail" label="Email address">
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="email@example.com"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}>
                    </Form.Control>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="pb-3">
                <InputGroup>
                    <FloatingLabel controlId="floatingPassword" label="Password">
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder="areallysecurepassword$@0^9"
                            value={password}
                            onChange={(e) => { setPassword(e.target.value) }}>
                        </Form.Control>
                    </FloatingLabel>
                    <Button variant="outline-primary">
                        <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
                    </Button>
                </InputGroup>
            </Form.Group>

            <Button className="w-100" type="submit">
                <FontAwesomeIcon className="me-2" icon={faEnvelope}></FontAwesomeIcon>
                Login with Email
            </Button>
            <Form.Text>
                Please use the same email & password as the Dealer Portal.
            </Form.Text>
        </Form>
    );
}

export default LoginForm;