/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';
import { Col, Spinner, Row } from 'react-bootstrap';
import { formatTag, removeTagSections, averageArr } from '../../helpers';
import { DashboardData, FBZenTicketResponse } from '../../react-app-env';
import DataCard from './DataCard';
import CardExportDropdown from "./CardExportDropdown"


//Chart.js imports
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Pie } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';




//Register chart.js scales
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

interface ReportingChartsProps {
    data: DashboardData | undefined,
    isLoading: boolean,
    chartRefs: {
        totalSolveRateBarRef: React.MutableRefObject<ChartJSOrUndefined<'bar'> | undefined>,
        topSolvedIssuesPieRef: React.MutableRefObject<ChartJSOrUndefined<'pie'> | undefined>,
        topEscalatedPieRef: React.MutableRefObject<ChartJSOrUndefined<'pie'> | undefined>
    }
}

const ReportingCharts: React.FC<ReportingChartsProps> = (props) => {
    const isFBZenTicketResponse = (ticket: any): ticket is FBZenTicketResponse => {
        return ticket && typeof ticket === 'object' && 'id' in ticket && 'custom_fields' in ticket;
    };

    // console.log(`Total tickets: ${props.data?.tickets?.length}`)

    // Filter tickets to exclude those with the specific custom field value
    const filteredTickets = props.data?.tickets?.filter(ticket => {
        if (!isFBZenTicketResponse(ticket)) return false;

        const customField = ticket.custom_fields.find(field => field.id === 10099183634199);
        // const isNoAccount = (customField?.value !== "10457450261655" && customField?.value !== 10457450261655) ? false : true;
        // if (isNoAccount) {
        //     console.log(`${ticket.id} is no account`)
        // }

        return (customField?.value !== "10457450261655" && customField?.value !== 10457450261655);
    });
    // console.log(`Filtered tickets: ${filteredTickets?.length}`)

    const pieBackgroundAnalagous = ['#6578C7', '#6B99D1', '#67A2B9', '#6BCFD1', '#65C7AD'];
    const barBackgroundMonochrome = [['#AABEC6', '#67A2B9'], ['#5D727A', '#325D6E']];

    const barChartTotals: Array<{ title: string, totalVal: number, escVal: number, notEscVal: number }> = [
        {
            title: "Auto Tickets",
            totalVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'api').length || 0,
            escVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'api' && (ticket.tags?.includes('escalatetodealer') || ticket.tags?.includes('is_escalated'))).length || 0,
            notEscVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'api' && !ticket.tags?.includes('escalatetodealer') && !ticket.tags?.includes('is_escalated')).length || 0
        },
        {
            title: "Voice Tickets",
            totalVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'voice').length || 0,
            escVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'voice' && (ticket.tags?.includes('escalatetodealer') || ticket.tags?.includes('is_escalated'))).length || 0,
            notEscVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'voice' && !ticket.tags?.includes('escalatetodealer') && !ticket.tags?.includes('is_escalated')).length || 0
        },
        {
            title: "Email Tickets",
            totalVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'email').length || 0,
            escVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'email' && (ticket.tags?.includes('escalatetodealer') || ticket.tags?.includes('is_escalated'))).length || 0,
            notEscVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'email' && !ticket.tags?.includes('escalatetodealer') && !ticket.tags?.includes('is_escalated')).length || 0
        },
        {
            title: "Web Tickets",
            totalVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'web').length || 0,
            escVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'web' && (ticket.tags?.includes('escalatetodealer') || ticket.tags?.includes('is_escalated'))).length || 0,
            notEscVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'web' && !ticket.tags?.includes('escalatetodealer') && !ticket.tags?.includes('is_escalated')).length || 0
        },
        {
            title: "SMS Tickets",
            totalVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'sms').length || 0,
            escVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'sms' && (ticket.tags?.includes('escalatetodealer') || ticket.tags?.includes('is_escalated'))).length || 0,
            notEscVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'sms' && !ticket.tags?.includes('escalatetodealer') && !ticket.tags?.includes('is_escalated')).length || 0
        },
        {
            title: "Chat Tickets",
            totalVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'chat').length || 0,
            escVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'chat' && (ticket.tags?.includes('escalatetodealer') || ticket.tags?.includes('is_escalated'))).length || 0,
            notEscVal: filteredTickets?.filter(ticket => isFBZenTicketResponse(ticket) && ticket.channel === 'chat' && !ticket.tags?.includes('escalatetodealer') && !ticket.tags?.includes('is_escalated')).length || 0
        },
    ];


    const avgBarVal = averageArr(barChartTotals.map(obj => obj.totalVal));
    const barChartDataLow = barChartTotals.map((obj) => obj.totalVal < avgBarVal ? obj : { title: obj.title, totalVal: 0, escVal: 0, notEscVal: 0 });
    const barChartDataHigh = barChartTotals.map((obj) => obj.totalVal >= avgBarVal ? obj : { title: obj.title, totalVal: 0, escVal: 0, notEscVal: 0 });
    console.log('barChartDataHigh:', barChartDataHigh);
    console.log('barChartDataLow:', barChartDataLow);
    const TotalSolveRateBar = forwardRef<ChartJSOrUndefined<'bar'>>((props, ref) => {
        return <Bar ref={ref}
            data={{
                labels: barChartTotals.filter(obj => obj.totalVal !== 0).map(({ title, totalVal }) => `${title} (${totalVal})`),
                datasets: [
                    {
                        label: "Escalated Tickets (High Volume)",
                        backgroundColor: barBackgroundMonochrome[0][0],
                        xAxisID: "xHigh",
                        data: barChartDataHigh.map(obj => obj.escVal)
                    },
                    {
                        label: "Escalated Tickets (Low Volume)",
                        backgroundColor: barBackgroundMonochrome[0][1],
                        xAxisID: "xLow",
                        data: barChartDataLow.map(obj => obj.escVal)
                    },
                    {
                        label: "Solved Remotely Tickets (High Volume)",
                        backgroundColor: barBackgroundMonochrome[1][0],
                        xAxisID: "xHigh",
                        data: barChartDataHigh.map(obj => obj.notEscVal)
                    },
                    {
                        label: "Solved Remotely Tickets (Low Volume)",
                        backgroundColor: barBackgroundMonochrome[1][1],
                        xAxisID: "xLow",
                        data: barChartDataLow.map(obj => obj.notEscVal)
                    }
                ],
            }}
            options={{
                indexAxis: "y",
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1,
                scales: {
                    y: {
                        type: "category",
                        stacked: true,
                    },
                    xHigh: {
                        stacked: true,
                        type: "linear",
                        position: 'top',
                        title: {
                            display: true,
                            text: "# Tickets (High Volume)",
                        },
                    },
                    xLow: {
                        stacked: true,
                        type: "linear",
                        position: 'bottom',
                        title: {
                            display: true,
                            text: "# Tickets (Low Volume)",
                        },
                    },
                },
                plugins: {
                    datalabels: {
                        display: true,
                        color: "white",
                        formatter: (val, ctx) => {
                            let otherDatasetIdx = undefined;
                            if (ctx.datasetIndex === 0 || ctx.datasetIndex === 2) {
                                otherDatasetIdx = ctx.datasetIndex === 0 ? 2 : 0;
                            } else {
                                otherDatasetIdx = ctx.datasetIndex === 1 ? 3 : 1;
                            }

                            const otherVal = ctx.chart.data.datasets[otherDatasetIdx].data[ctx.dataIndex];
                            const total = val + otherVal;

                            if (val === 0 || total === 0 || isNaN(total)) {
                                return '';
                            }

                            const percentage = ((val / total) * 100).toFixed(2);
                            return `${percentage}%`;
                        },
                    },
                    legend: {
                        labels: {
                            boxWidth: 12,
                            filter: (item) => item.text !== "none",
                        },
                    },
                },
            }}
        />;
    });

    const aggregateReasons = (dataArray: { value: string; count: number; }[]) => {
        const reasonCounts: { [reason: string]: number } = {};

        dataArray.forEach(item => {
            const reasons = item.value.split('|@|');
            reasons.forEach(reason => {
                const formattedReason = formatTag(removeTagSections(reason, 1));
                if (reasonCounts[formattedReason]) {
                    reasonCounts[formattedReason] += item.count;
                } else {
                    reasonCounts[formattedReason] = item.count;
                }
            });
        });

        const aggregatedData = Object.keys(reasonCounts).map(reason => ({
            reason,
            count: reasonCounts[reason]
        }));

        aggregatedData.sort((a, b) => b.count - a.count);

        return aggregatedData.slice(0, 5);
    };

    const TopSolvedIssuesPie = forwardRef<ChartJSOrUndefined<'pie'>, { data: DashboardData }>((props, ref) => {
        const aggregatedData = aggregateReasons(props.data?.theReasonForCallCountForNotEscalated || []);
        return <Pie ref={ref} data={{
            labels: aggregatedData.map(item => item.reason),
            datasets: [
                {
                    label: 'Top 5 Solved Issues',
                    data: aggregatedData.map(item => item.count),
                    backgroundColor: pieBackgroundAnalagous,
                    datalabels: {
                        color: 'white'
                    }
                }
            ]
        }} options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom' as const,
                },
                datalabels: {
                    display: true
                }
            }
        }} />;
    });

    const TopEscalatedPie = forwardRef<ChartJSOrUndefined<'pie'>, { data: DashboardData }>((props, ref) => {
        const aggregatedData = aggregateReasons(props.data?.theReasonForCallCountForEscalated || []);
        return <Pie ref={ref} data={{
            labels: aggregatedData.map(item => item.reason),
            datasets: [
                {
                    label: 'Top 5 Escalated Issues',
                    data: aggregatedData.map(item => item.count),
                    backgroundColor: pieBackgroundAnalagous,
                    datalabels: {
                        color: 'white'
                    }
                }
            ]
        }} options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom' as const,
                },
                datalabels: {
                    display: true
                }
            }
        }} />;
    });

    return (
        <Row>
            <Col xs={12} xxl={6} className="pb-4" style={{ minHeight: "40vh" }}>
                <DataCard
                    header={
                        <div className="w-100 d-inline-flex justify-content-between align-items-center">
                            Total Solve Rate
                            <CardExportDropdown
                                exportOptions={['PNG', 'PDF', 'ALL']}
                                chartRef={props.chartRefs.totalSolveRateBarRef}
                                supportedFileTypes={['PNG', 'PDF', 'ALL']}
                                fileName="Total Solve Rate Bar Chart"
                                disabled={props.isLoading}
                            />
                        </div>
                    }
                    body={
                        props.data !== undefined && !props.isLoading ? <TotalSolveRateBar ref={props.chartRefs.totalSolveRateBarRef} /> :
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center"><Spinner animation="border" variant="primary" /></div>
                    }
                />
            </Col>
            <Col xs={12} md={6} xxl={3} className="pb-4" style={{ minHeight: "40vh" }}>
                <DataCard header={
                    <>
                        Top Solved Issues
                        <CardExportDropdown
                            exportOptions={['PNG', 'PDF', 'ALL']}
                            chartRef={props.chartRefs.topSolvedIssuesPieRef}
                            supportedFileTypes={['PNG', 'PDF', 'ALL']}
                            fileName="Top Solved Issues Pie Chart"
                            disabled={props.isLoading}
                        />
                    </>
                }
                    body={
                        props.data !== undefined && !props.isLoading ? <TopSolvedIssuesPie ref={props.chartRefs.topSolvedIssuesPieRef} data={props.data} /> :
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center"><Spinner animation="border" variant="primary" /></div>
                    } />
            </Col>
            <Col xs={12} md={6} xxl={3} className="pb-4" style={{ minHeight: "40vh" }}>
                <DataCard header={
                    <>
                        Top Escalated Issues
                        <CardExportDropdown
                            exportOptions={['PNG', 'PDF', 'ALL']}
                            chartRef={props.chartRefs.topEscalatedPieRef}
                            supportedFileTypes={['PNG', 'PDF', 'ALL']}
                            fileName="Top Escalated Issues Pie Chart"
                            disabled={props.isLoading}
                        />
                    </>
                } body={
                    props.data !== undefined && !props.isLoading ? <TopEscalatedPie ref={props.chartRefs.topEscalatedPieRef} data={props.data} /> :
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center"><Spinner animation="border" variant="primary" /></div>
                }
                />
            </Col>
        </Row>
    );
}

export default ReportingCharts;

