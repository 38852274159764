//React imports
import React from 'react';

//React Bootstrap imports
import { Row, Col, Button, Form, InputGroup, Modal } from 'react-bootstrap';

//Typeahead imports
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from '../../react-app-env';

//Font Awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsDownToLine, faFloppyDisk, faFileExport } from '@fortawesome/free-solid-svg-icons'


interface CustomReportModalProps {
    show: boolean,
    onHide: () => void,
    usersCustomReports: Array<object>,
    usersClients: Array<object>
}

interface CustomReportModalState {
    selectedSetting: Array<Option>,
    selectedClients: Array<Option>,
    allMetrics: { [key: string]: { label: string, checked: boolean } }
    allAttributes: { [key: string]: { label: string, checked: boolean } }
    reportOnAllClients: boolean,
    startDate: Date,
    endDate: Date,
}

class CustomReportModal extends React.Component<CustomReportModalProps, CustomReportModalState> {

    constructor(props: CustomReportModalProps) {
        super(props)
        this.state = {
            selectedSetting: [],
            selectedClients: [],
            allMetrics: {
                totalTickets: { label: "Total Tickets", checked: false },
                solvedTickets: { label: "Solved Tickets", checked: false },
            },
            allAttributes: {
                reasonForCall: { label: "Reason for Call", checked: false },
                ticketSource: { label: "Ticket Source", checked: false },
                dayCreated: { label: "Ticket Created - Day", checked: false },
                hourCreated: { label: "Ticket Created - Hour", checked: false },
                dateCreated: { label: "Ticket Created - Date", checked: false },
                isEscalated: { label: "Escalated (yes/no)", checked: false },
                isAllCall: { label: "AllCall (yes/no)", checked: false }
            },
            reportOnAllClients: false,
            startDate: new Date(),
            endDate: new Date(),
        }
    }

    render() {

        return (
            <Modal
                show={this.props.show}
                onHide={() => {
                    // setSelectedSetting([] as Array<object>)
                    // setSelectedClients([] as Array<object>)
                    this.props.onHide()
                }}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Generate Custom Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label className="fs-5">Re-use Saved Settings</Form.Label>
                            <InputGroup>
                                <Typeahead
                                    id="saved-report-settings-typeahead"
                                    labelKey="label"
                                    onChange={(settingOptions: Array<Option>) => { this.setState({ selectedSetting: settingOptions }) }}
                                    options={this.props.usersCustomReports}
                                    placeholder="Type a name or select saved settings..."
                                    selected={this.state.selectedSetting}
                                />
                                <Button variant="outline-primary">
                                    <FontAwesomeIcon icon={faArrowsDownToLine}></FontAwesomeIcon>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <hr className="hr-text" data-content="OR" />
                        <Form.Label className="fs-5">Enter Custom Report Settings</Form.Label>
                        <Form.Group className="pb-4">
                            <Row>
                                <Col>
                                    <Typeahead
                                        id="selected-clients-typeahead"
                                        labelKey="ovrcName"
                                        onChange={(selectedClients: Array<Option>) => { this.setState({ selectedClients: selectedClients }) }}
                                        options={this.props.usersClients}
                                        placeholder="Type an account name or select an account..."
                                        selected={this.state.selectedClients}
                                        disabled={this.state.reportOnAllClients}
                                        multiple
                                    />
                                </Col>
                                <Col sm="auto" className="d-flex align-items-center">
                                    <Form.Text>- OR -</Form.Text>
                                </Col>
                                <Col sm="auto" className="d-flex align-items-center">
                                    <Form.Check
                                        inline
                                        label="All clients"
                                        name="group1"
                                        type="checkbox"
                                        id="report-on-all-clients-checkbox"
                                        checked={this.state.reportOnAllClients}
                                        onChange={(e) => { this.setState({ reportOnAllClients: e.target.checked }) }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as="div" className="d-flex justify-content-between px-3 pb-4">
                            <Form.Group as={Row}>
                                <Form.Label column sm="auto">Date Range: </Form.Label>
                                <Col sm="auto">
                                    <Form.Control type="date" onChange={(e) => { this.setState({ startDate: new Date(e.target.value) }) }} />
                                </Col>
                                <Form.Text as={Col} sm="auto" className="fs-5 px-0" >—</Form.Text>
                                <Col sm="auto">
                                    <Form.Control type="date" onChange={(e) => { this.setState({ endDate: new Date(e.target.value) }) }} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="d-flex align-items-center">
                                <Form.Label column sm="auto">File Type:</Form.Label>
                                <Col sm="auto"><Form.Check type="radio" name="fileType" label="PDF" /></Col>
                                <Col sm="auto"><Form.Check type="radio" name="fileType" label="XLS" /></Col>
                                <Col sm="auto"><Form.Check type="radio" name="fileType" label="CSV" /></Col>
                            </Form.Group>
                        </Form.Group>
                        <Form.Group as={Row} className="d-flex justify-content-between px-3 pb-4">
                            <Form.Group as={Col} sm={3}>
                                <Form.Label>Metric(s)</Form.Label>
                                {Object.keys(this.state.allMetrics).map((metric) => (
                                    <Form.Check
                                        key={metric}
                                        type="checkbox"
                                        label={this.state.allMetrics[metric].label}
                                        checked={this.state.allMetrics[metric].checked}
                                        onChange={() => {
                                            this.setState({
                                                allMetrics: {
                                                    ...this.state.allMetrics,
                                                    [metric]: {
                                                        ...this.state.allMetrics[metric],
                                                        checked: !this.state.allMetrics[metric].checked
                                                    }
                                                }
                                            });
                                        }}
                                    />
                                ))}
                                {/* {Object.entries(this.state.allMetrics).map(([key, value], idx) => (
                                    <Form.Check
                                        type="checkbox"
                                        label={value.label}
                                        name="selectedMetrics"
                                        checked={value.checked}
                                        onChange={(e) => { 
                                            this.setState({ 
                                                allMetrics: { 
                                                    ...this.state.allMetrics, 
                                                    [key]: {
                                                        ...this.state.allMetrics[key], 
                                                        checked: !value.checked 
                                                    }
                                                }
                                            })
                                        }
                                    }></Form.Check>
                                ))} */}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Attribute(s)</Form.Label>
                                {Object.keys(this.state.allAttributes).map((attribute) => (
                                    <Form.Check
                                        key={attribute}
                                        type="checkbox"
                                        label={this.state.allAttributes[attribute].label}
                                        checked={this.state.allAttributes[attribute].checked}
                                        onChange={() => {
                                            this.setState({
                                                allAttributes: {
                                                    ...this.state.allAttributes,
                                                    [attribute]: {
                                                        ...this.state.allAttributes[attribute],
                                                        checked: !this.state.allAttributes[attribute].checked
                                                    }
                                                }
                                            });
                                        }}
                                    />
                                ))}
                                {/* {Object.entries(this.state.allAttributes).map(([key, value], idx) => (
                                    <Form.Check
                                        type="checkbox"
                                        label={value.label}
                                        name="selectedMetrics"
                                        checked={value.checked}
                                        onChange={(e) => {
                                            this.setState({
                                                allAttributes: {
                                                    ...this.state.allAttributes,
                                                    [key]: {
                                                        ...this.state.allAttributes[key],
                                                        checked: !value.checked
                                                    }
                                                }
                                            })
                                        }
                                        }></Form.Check>
                                ))} */}

                            </Form.Group>
                        </Form.Group>
                        <div className="w-100 d-flex justify-content-between">
                            <Button variant="primary">
                                <FontAwesomeIcon icon={faFloppyDisk} className="me-2"></FontAwesomeIcon>
                                Save Settings
                            </Button>
                            <Button variant="primary" type="submit">
                                <FontAwesomeIcon icon={faFileExport} className="me-2"></FontAwesomeIcon>
                                Generate Report
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default CustomReportModal;