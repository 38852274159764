import { FunctionComponent } from "react";

interface ParasolLogoProps {
    fill?: string,
    stroke?: string,
    strokeWidth?: string
    height?: string
    width?: string
    className?: string
}

const ParasolLogo: FunctionComponent<ParasolLogoProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : '100%'} height={props.height ? props.height : '100%'} viewBox="0 0 253 114" className={props.className}>
            <path id="Selection" fill={props.fill} stroke={props.stroke} strokeWidth={props.strokeWidth}
                d="M 5.00,104.00
                C 0.50,101.00 17.91,64.38 27.81,52.00
                  44.99,30.51 65.62,16.86 92.00,9.29
                  100.48,6.86 109.15,5.01 118.00,5.00
                  118.00,5.00 137.00,5.00 137.00,5.00
                  154.19,5.20 177.45,13.89 192.00,22.81
                  214.62,36.67 230.72,55.70 241.13,80.00
                  244.22,87.21 251.50,104.75 247.00,107.00
                  242.50,109.25 235.79,99.11 230.00,95.04
                  219.90,87.94 204.56,81.55 192.00,82.10
                  180.78,82.60 168.40,86.61 159.00,92.70
                  144.37,102.17 144.73,110.24 124.00,110.00
                  109.37,109.83 105.37,101.63 95.00,94.02
                  84.65,86.42 69.03,80.35 56.00,81.09
                  45.38,81.69 33.96,84.94 25.00,90.70
                  18.82,94.67 9.50,107.00 5.00,104.00 Z"
            />
        </svg>
    )

}

export default ParasolLogo;